<template>
  <div>
    <section>
      <article class="user_info d-flex align-items-center justify-content-center">
        <div class="user_info_description pt-5 pb-5">
          <div class="container">
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="text-center">
                <h2>Write to Kinawataka Women Association</h2>
                <!-- <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto velit illum saepe vel eum porro animi molestiae vero, eveniet et temporibus ex sed, odio aut omnis eligendi blanditiis dolor voluptas?
                </p> -->
              </div>
              <div>
                <el-form
                  :model="shippingForm"
                  :rules="shippingFormRules"
                  label-position="top"
                  ref="shippingRuleForm"
                  label-width="120px"
                  class="demo-shippingRuleForm"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item label="First name" prop="fname">
                        <el-input v-model="shippingForm.fname"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item label="Surname" prop="lname">
                        <el-input v-model="shippingForm.lname"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <el-form-item label="Phone Number" prop="phone">
                        <el-input v-model="shippingForm.phone"></el-input>
                      </el-form-item>
                    </div>
                    <div class="col-md-6">
                      <el-form-item label="Email" prop="email">
                        <el-input v-model="shippingForm.email"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item label="Message" prop="email">
                        <textarea v-model="shippingForm.email" placeholder="Type message here..." class="form-control"/>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <el-form-item>
                        <el-button
                          class="btn btn-block"
                          style="background-color: #716658; color: white; border-color: #716658;"
                          @click="ContinueToPayment('shippingRuleForm')"
                          >Send Message
                        </el-button>
                      </el-form-item>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        
        
      shippingForm: {
        fname: "",
        lname: "",
        email: "",
        phone: "",
        address: "",
        country: "",
        shippingMethod: "delivery",
      },
      shippingFormRules: {
        fname: [
          {
            required: true,
            message: "Please input First Name",
            trigger: "blur",
          },
        ],
        lname: [
          {
            required: true,
            message: "Please input Last Name",
            trigger: "blur",
          },
        ],
        email: [
          { required: true, message: "Please enter email", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            message: "Please enter phone number",
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "Please enter Address", trigger: "blur" },
        ],
        country: [
          {
            required: true,
            message: "Please select your Country",
            trigger: "change",
          },
        ],
      },

      country_list: [
        "Uganda",
        "Kenya",
        "Tanzania",
        "Rwanda",
        "Burundi",
        "South Sudan",
      ],

      };
    },
    methods: {

    async ContinueToPayment(shippingRuleForm) {
      
      this.$refs[shippingRuleForm].validate(async (valid) => {
        if (valid) {
          console.log("Submitted...");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    },
  };
</script>

<style scoped>

  .user_info {
    background-image: linear-gradient(rgba(104,68,47,0.9), rgba(104,68,47,0.9)), url(../../assets/images/straw.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .user_info_description {
    width: 100%;
    color: white;
  }

  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
    
  }

  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  }

  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
    
    

  }

  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {

    

  }
</style>

<style>
    .input-class .el-input__inner{
        width: 50px !important; 
    }
.el-form--label-top .el-form-item__label {
    float: none;
    display: inline-block;
    text-align: left;
    padding: 0 0 0px;
    color: white;
}

.el-form-item__label {
    color: white;
}


    .el-date-editor .el-range-input {
        /* width: 20% !important; */
    }

    .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
        
    }

        
        
    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) {
        
    }

    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {

    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
       
       

    }
</style>