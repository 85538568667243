<template>
  <div>
    <article class="theCarouselArticle">straws22
      <el-carousel height="100vh" :interval="5000" arrow="always">
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/straws22.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <div>
                  <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN ASSOCIATION</small>
                  </p>
                  <p>Women Recycling straws.</p>
                  <button class="btn btn-md moreBtn">
                    Explore more
                    <i class="el-icon-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/home/5.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                <div>
                  <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN ASSOCIATION</small>
                  </p>
                  <p>Women Recycling straws.</p>
                  <button class="btn btn-md moreBtn">
                    Explore more
                    <i class="el-icon-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/home/6.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                  <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN ASSOCIATION</small>
                  </p>
                  <p>Women Recycling straws.</p>
                <button class="btn btn-md moreBtn">
                  Explore more
                  <i class="el-icon-right"></i>
                </button>
              </div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="carousel_description">
            <img
              src="../../assets/images/home/1.jpg"
              class="d-block w-100"
              alt="..."
            />
            <div class="image-description">
              <div class="container">
                  <p class="locus_word_style">
                    <small>KINAWATAKA WOMEN ASSOCIATION</small>
                  </p>
                  <p>Women Recycling straws.</p>
                <button class="btn btn-md moreBtn">
                  Explore more
                  <i class="el-icon-right"></i>
                </button>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </article>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      num: 1,
      value1: '',
      //3461
    };
  },
  methods: {
    handleChange(value) {
      console.log(value);
    },
  },
};
</script>

<style scoped>

  .locus_word_style {
    font-size: 0.5em; font-weight: 300;
    word-spacing: 8px;
    letter-spacing: 5px;
  }
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.carousel_description {
  position: relative;
  height: 100vh;
}
.carousel_description img {
  height: 100vh;
  object-fit: cover;
}

.carousel_description .image-description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  /* margin-left:10%;
    margin-right:10%; */
  padding-bottom: 10%;
  background-image: linear-gradient(
    to bottom left,
    rgba(255, 255, 255, 0),
    rgba(61, 41, 10, 0.5)
  );
  display: flex;
  align-items: flex-end;
}

.carousel_description .image-description > div {
  /* background-color: green; */
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
  text-align: center;
}

.moreBtn {
  background-color: transparent;
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.moreBtn:hover {
  background-color: rgba(61, 41, 10, 0.5);
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.theCarouselArticle {
  height: 100vh;
  background-color: #EBEED7;
  position: relative;
}

.availabilityForm {
 display: flex;
 align-items: flex-end;
 justify-content: space-between;

}

.availabilityForm >div{
    font-size: 0.8em;
}

.checkBtn {
    background-color: #D96B12;
    border: 2px solid #D96B12;
    color: #fbf2e1;
    border-radius: 0px;
 text-transform: uppercase;
}


    
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
    
  }

  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  }

  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
    
    .availabilityForm {
        display: block;
    }

    .checkBtn {
        width: 100%;
    }
   
    .carousel_description .image-description {
        padding-bottom: 55%;
    }

    .peopleNo {
      margin-top: 20px;
    }
  }

  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {

    .availabilityForm {
        display: block;
    }

    .checkBtn {
        width: 100%;
    }
   
    .carousel_description .image-description {
        padding-bottom: 55%;
    }

    .peopleNo {
      margin-top: 20px;
    }

  }
</style>

<style>
.el-carousel__button {
    display: block;
    opacity: .48;
    width: 10px;
    height: 10px;
    background-color: #FFF;
    border: none;
    outline: 0;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: .3s;
    border-radius: 50%;
}
    .input-class .el-input__inner{
        width: 50px !important; 
    }

    .el-date-editor .el-range-input {
        /* width: 20% !important; */
    }

    .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
        width: 100% !important;
    }

        
        
    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) {
        
    }

    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {

    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
            
        .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
            margin-bottom: 20px;
        }

        .el-input-number.is-controls-right {
            width: 100%;
            margin-bottom: 20px;
        }

    }
</style>
