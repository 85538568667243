<template>
  <div>
    <div style="background-color: #e7ebce;" class="pb-5">
      <div class="container">
        <div class="row pt-5 pb-5">
          <div class="col-md-2"></div>
          <div class="home_welcome_message col-md-8 text-center">
            <h2>
              <span id="h22">WHAT WE DO</span>
            </h2>
            <!-- <p style="font-size: 1.2em; font-weight: 500; color: grey">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Excepturi, blanditiis.
            </p> -->
          </div>
          <div class="col-md-2"></div>
        </div>
        <div class="welcome_grid">
          <div
            class="welcome_flex"
            v-for="message in welcome_messages"
            :key="message.id"
          >
            <div class="welcome_image">
              <img :src="message.photo" />
            </div>
            <div class="welcome_description">
              <div>
                <span class="heading">{{ message.title }}</span><br />
              </div>
              <div>
                <ul>
                  <li>
                    <span>
                      {{ message.description }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <button 
                @click="$router.push({ path: `/${message.route_path}` })"
                class="btn btn-block"
                style="background-color: #A1AE39; color: white;"
              >
                Learn More <i class="el-icon-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div style="background-color: #f5f0ed;" class="">
        <div class="container-fluid2">
          <div class="row pt-5 pb-5">
            <div class="col-md-2"></div>
            <div class="home_welcome_message col-md-8 text-center">
              <h2>
                <span id="h22">OUR PRODUCTS</span>
              </h2>
              <p style="font-size: 1.5em; font-weight: 500; color: grey">
                Explore our hand made straw products, and palm leaves products. 
              </p>
            </div>
            <div class="col-md-2"></div>
          </div>
          <div class="product_grid">
            <div
              class="product_flex"
              v-for="product in product_categories"
              :key="product.id"
            >
              <div class="product_image">
                <img :src="product.photo" />
              </div>
              <div class="product_description text-center">
                <div class="w-100 mb-4">
                  <span class="d-inline-block text-truncate heading"
                  style="max-width: 80%">{{ product.title }}</span><br />
                  <span class="wel_text">Greatest Compilation of African Art.</span><br/>
                  <span
                    @click="$router.push({ path: `/${product.route_path}` })" 
                    class="wel_text" 
                    style="color: #9EAB37; font-weight: 600;"
                  >
                    Explore More <span class="el-icon-right"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {

      welcome_messages: [
        {
          id: "1",
          title: "Recycling Straws.",
          description:
            "The unique straw products are made from used plastic straws, which the women and youths collect, wash, sterilize and dry in shade.",
          photo: require("../../assets/images/home/5.jpg"),
          price: 3000,
          country: "Uganda",
          route_path: "recycling-straws",
        },
        {
          id: "2",
          title: "Women Empowerment.",
          description:
            "To date approximately 7940 women and single mothers and 6000 youths, school drop outs as well as people living with HIV/AIDS have been skilled and empowered.",
          photo: require("../../assets/images/straw_dress3.jpg"),
          price: 6000,
          country: "Uganda",
          route_path: "women",
        },
        {
          id: "3",
          title: "Caring for the needy children",
          description:
            "We identify needy children from different homes and support them to attain education. We also train them to recycle used straws into different products",
          photo: require("../../assets/images/children/children2.jpg"),
          price: 8000,
          country: "Uganda",
          route_path: "children",
        },
      ],

      product_categories: [
        {
          id: "1",
          title: "Mats",
          photo: require("../../assets/images/products/mat.jpg"),
          route_path: "our-products",
        },
        {
          id: "2",
          title: "Bags",
          photo: require("../../assets/images/products/bag.jpg"),
          route_path: "our-products",
        },
      ],
    };
  },

  methods: {
    accommodationDetail(roomId) {
      this.$router.push({ path: `accommodation-detail/${roomId}` });
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
  padding: 0px;
}
ul li {
  color: grey;
}

h2 {
  font-weight: 600;
}

.home_welcome_message > h2 #h21 {
  font-size: 0.6em;
}

h2 #h22 {
  font-size: 1.5em;
  color: #68442f;
}
.welcome_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
}

.welcome_grid > div {
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

.welcome_grid > div:hover {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  transition: 0.5s;
}

.welcome_flex {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.welcome_flex > div:nth-child(3) {
  border-radius: 0px 0px 5px 5px;
}

.welcome_grid .welcome_image {
  height: 250px;
  border-radius: 5px;
}
.welcome_grid .welcome_image img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.welcome_grid .welcome_description {
  padding-top: 20px;
  /* padding-bottom: 0px; */
  background-color: white;
  /* border-radius: 5px; */
}

.welcome_grid .welcome_description .heading {
  font-weight: 600;
  font-size: 1.2em;
}
.welcome_grid .welcome_description .wel_text {
  color: grey;
  font-size: 1.2em;
}

.destination_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}

.destination_grid > div {
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;

  position: relative;
}

.destination_grid > div:hover {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  transition: 0.5s;
}

.destination_image img {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.destination_text {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  color: white;
}

.product_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  /* color: #f5f0ed; */
}

.product_grid > div {
  border-radius: 5px;
  background-color: #775741;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.5s;
}

.product_grid > div:hover {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  transition: 0.5s;
}

.product_flex {
  position: relative;
}

.product_flex > div:nth-child(3) {
  border-radius: 0px 0px 5px 5px;
}

.product_grid .product_image {
  height: 550px;
  border-radius: 5px;
}
.product_grid .product_image img {
  height: 550px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0px 0px;
}

.product_grid .product_description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.8));

  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.product_grid .product_description .heading {
  font-weight: 600;
  font-size: 2em;
}
.product_grid .product_description .wel_text {
  font-size: 1.2em;
}

.btn-brown {
  background-color: #d96b12;
  border-radius: 0px 0px 5px 5px;
  color: white;
  width: 100%;
}

.btn-view-more {
  background-color: white;
  border: 1px solid #d96b12;
  width: 250px;
}

.rooms {
  background-color: #eeeeee;
}

.rooms_grid {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
  border-radius: 5px;
}

.rooms_grid > div {
  text-align: left;
  padding: 20px;
  border-radius: 5px;
}

.rooms_grid > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.rooms_grid .rooms_image img {
  height: 260px;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.rooms_grid ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
  .welcome_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .welcome_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .destination_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
    
  .product_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .home_welcome_message {
    margin-top: 80px;
  }
  .home_welcome_message > h2 #h21 {
    font-size: 0.6em;
  }

  h2 #h22 {
    font-size: 0.8em;
    color: #d96b12;
  }
  .welcome_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
  

  .destination_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
    
  .product_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .home_welcome_message {
    margin-top: 80px;
  }
  h2 #h21 {
    font-size: 0.6em;
  }

  h2 #h22 {
    font-size: 0.8em;
    color: #d96b12;
  }

  .destination_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
    
  .product_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .welcome_grid {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .welcome_grid .welcome_image {
    height: 150px;
    border-radius: 5px;
  }
  .welcome_grid .welcome_image img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .welcome_grid .welcome_description {
    padding-top: 20px;
    background-color: white;
    border-radius: 5px;
  }

  .welcome_grid .welcome_description .heading {
    font-size: 0.7em;
  }
  .welcome_grid .welcome_description .wel_text {
    font-size: 0.8em;
  }

  .cottage_welcome h2 {
    font-size: 1.3em;
  }

  .cottage_welcome p {
    font-size: 0.85em;
  }

  .cottages_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .cottages_grid .welcome_image {
    height: 250px;
    border-radius: 5px;
  }
  .cottages_grid .welcome_image img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .cottages_grid .welcome_description {
    text-align: center;
    padding: 50px 10px;
    background-color: white;
    border-radius: 5px;
  }

  .cottages_grid .welcome_description .heading {
    font-size: 0.7em;
  }
  .cottages_grid .welcome_description .wel_text {
    font-size: 0.8em;
  }

  .rooms h2 {
    font-size: 1.2em;
  }
  .rooms p {
    font-size: 0.8em;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .btn-brown {
    background-color: #d96b12;
    color: white;
    width: 100%;
    font-size: 0.7em;
  }
}
</style>